@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika+Negative:wght@300..700&family=Signika:wght@300..700&display=swap');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

*, html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Signika Negative, Arial, sans-serif;
}

:root {
  --gblue: #205fb0;
  --blue: #03162e;
  --alertPrimary: #ccf2ff;
  --alertSecondary: #E2E3E3;
  --alertWarning: #ffff99;
  --alertError: #ffb3b3;
  --alertSuccess: #d6f5d6;

  --background: #000;
  --paragraphs: #3b4555;
  --heading: #0c1d37;
  /* --dub-brown: #edae85; */
  --dub-brown: #07070b;
  --orange: #ff6501;
  --border: #efe2d7;
  --shadow: rgba(100, 44, 0, .07);
  --white: white;
  --8145fd: #ff5053;
  --0c0416: #07070b;
  --dub-gray: #a9a2a4;
  --dub-transparent: rgba(70, 70, 70, .19);
  --accent-background: #fbf2eb;
  --green: #24F37B;
  --dub-off-white: white;
  --dub-dark-gray: #5f5f5f;
  --dub-green: var(--paragraphs);

  --grey: #dfdfdf;

  --ddk: #07061f;
}

.text-brown {
  color: #d7ad93
}

body {
  /* overflow: hidden; */
  height: 100%;
}

a {
  text-decoration: none;
  color: #151515;
}

ul {
  list-style: none;
}

.r-app {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.r-app:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assets/pexels-daniel-dan-47825192-7708809.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  overflow: hidden;
  opacity: .15;
}

.content {
  flex: 1;
  overflow: hidden;
  /* overflow-y: auto; */
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.hr-row {
  display: flex;
  align-items: center;
}

.d-col {
  display: flex;
  flex-direction: column;
}

.text-ddk {
  color: var(--ddk);
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-16 {
  margin-bottom: 40px;
}

.mb-32 {
  margin-bottom: 40px;
}

.mb-64 {
  margin-bottom: 48px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.d-col-row {
  display: flex;
  flex-direction: column;
}

.d-row-col {
  display: flex;
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.curve-10 {
  border-radius: 10px;
}

.curve-circle {
  border-radius: 50%;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #151617;
}

.btn {
  display: block;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px 8px;
  background-color: darkgray;
  cursor: pointer;
}

.btn:focus, .btn:hover, .btn:active {
  outline: none;
  border: none;
}

.form-control:focus, .form-control:hover, .form-control:active {
  outline: none;
  border: none;
  box-shadow: 0px 0px 2px #000;
}

.shadow {
  box-shadow: 0px 0px 5px #999;
}

.btn-full {
  display: block;
  width: 100%;
}

.btn-primary {
  background-color: blue;
}

.card {
  position: relative;
  width: 600px;
  padding: 10px;
  margin: 10% auto;
}

.form-control {
  width: 100%;
  border: none;
  outline: none;
  padding: 15px 10px;
  border-radius: 5px;
  font-size: larger;
  background-color: #fff;
}

.input-field {
  margin: 15px 0;
  display: block;
}

.input-with-icon .form-control {
  padding: 10px;
}

.input-with-icon {
  display: flex;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  padding: 5px 10px;
}

.input-with-icon:hover, .input-with-icon:focus {
  box-shadow: 0px 0px 2px #000;
}

.p-1 {
  padding: 5px;
}

.curve-5 {
  border-radius: 5px;
}

.input-with-icon .form-control:focus, .input-with-icon .form-control:hover, .input-with-icon .form-control:active {
  outline: none;
  border: none;
  box-shadow: none;
}

.w-full {
  width: 100%;
}

.w-400 {
  width: 400px;
}

.w-600 {
  width: 600px;
}

.w-200 {
  width: 200px;
}

.h-full {
  height: 100%;
}

.h-200 {
  height: 200px;
}

.h-400 {
  height: 400px;
}

.h-1vh {
  height: 100vh;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-3 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-4 {
  margin-left: 15px;
  margin-right: 15px;
}

/*  */
.header {
  position: relative;
  height: 80px;
}

h1 {
  color: #fff;
}

.bg-black {
  background-color: #151515;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.bd-1 {
  border: 2px solid rgb(21, 14, 14);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-blue-light {
  background-color: var(--gblue);
}

.bg-dark-blue {
  background-color: var(--blue);
}

.bg-dark-blue-2 {
  background-color: #020a14;
}

.bg-d-grey {
  background-color: rgba(83, 79, 79, 0.9);
}

.landing-vid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  opacity: 0.1;
}

.jk h1 {
  color: var(--ddk);
}

.text-d-blue {
  color: #03162e;
}

.text-d-blue-2 {
  color: #020a14;
}

.vv {
  background: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .75));
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}

.sidebar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  gap: 1.5rem;
}

.sidebar li {
  color: #fff;
}


.sidebar li a {
  color: #fff;
}


.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.navToggler {
  display: none;
}

.sidebar .g {
  display: none;
}

.text-bold {
  font-weight: bold;
}

.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}


.pos-fixed {
  position: fixed;
}

.sidebar li a span {
  display: none;
}

.d-block {
  display: block;
}

.w-half {
  width: 50%;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 450px) {
  .w-400 {
    width: 98%;
  }

  .d-col-row {
    display: flex;
    flex-direction: row;
  }

  .d-row-col {
    display: flex;
    flex-direction: column;
  }


  .container {
    width: 98% !important;
  }

  .w-half {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 900px;
  }
}

@media screen and (max-width: 600px) {
  .sidebar .g {
    display: block;
    height: 200px;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    background-image: url('assets/pexels-sandra-filipe-64798-7087668.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container {
    width: 95% !important;
  }

  .w-600 {
    width: 100%;
  }

  .card {
    width: 100%;
    margin: auto auto;
  }

  .sidebar li a span {
    display: block;
  }

  .sidebar .g::before {
    content: '';
    background-color: rgba(21, 21, 21, .55);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .sidebar li a {
    display: flex;
    align-self: center;
    gap: .5rem;
    width: 100%;
    margin: 12px 0;
    padding: 10px;
    color: #151515;
  }

  .sidebar {
    display: block;
    background-color: #dfdfdf;
    height: 100vh;
    width: 250px;
    position: absolute;
    top: 0;
    right: -300px;
    z-index: 3;
    transition: right .3s linear;
  }

  .navToggler {
    font-size: x-large;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    display: block;
  }
}

.trow-1 {
  border-bottom: 1px solid #151617;
}

.p-2 {
  padding: 10px;
}

.cc {
  min-width: 25%;
  height: 45px;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  font-size: small;
}

.cc:nth-child(even) {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.cc_ {
  min-width: 28%;
  height: 45px;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.cc:hover {
  background-color: #fff;
  cursor: pointer;
}

.trow-data {
  min-width: 25%;
  height: 80px;
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  font-size: small;
}

.trow-dataf {
  min-width: 28%;
  height: 80px;
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  font-size: small;
}

.btn-curve {
  border-radius: 80px;
}

.magic-btn {
  padding: 32px 10px;
}

.magic-btn:hover {
  background-color: #205fb0;
  color: #fff;
}

.spacex-2 {
  gap: 1rem;
}

.table-row:hover {
  cursor: pointer;

}

.bg-white {
  background-color: #fff;
}

.table-row:nth-child(odd) {
  box-shadow: 0 0 5px gray;
}


.trow-dataf .nn {
  font-size: x-small;
  background-color: #dfdfdf;
  padding: 8px 4px;
}

.nm {
  background-color: #dfdfdf;
  padding: 8px 4px;
}

.trow-dataf img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-1 {
  margin-left: 5px;
}

.table-div {
  min-width: 380px;
}

.fw-bold {
  font-weight: bold;
}

.bg-yellow {
  background-color: #dfdfdf;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.img-cover {
  object-fit: cover;
}

.qrcode canvas {
  width: 150px;
}

.table-body {
  background-color: rgba(244, 244, 244, .5);
  overflow-y: auto;
  /* height: 360px; */
}

.text-small {
  font-size: small;
}

.text-center {
  text-align: center !important;
}

.mt-4 {
  margin-top: 15px;
}

.mt-2 {
  margin-top: 8px;
}

.circle-30-full {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.circle-40-full {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.circle-50-full {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.circle-80-full {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.circle-100-full {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.text-mid {
  font-size: smaller;
}

.bd-1 {
  border-bottom: 1px solid #fff;
}

.text-xl {
  font-size: larger;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.bg-grey {
  background-color: var(--grey);
}

.bg-green {
  background-color: #1e783f;
}

.bg-gren {
  background-color: var(--blue);
}

.bg-gren-light {
  background-color: var(--gblue);
}

.bg-gray {
  background-color: #23344b;
}

.floating {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 60px;
  left: 10px;
  background-color: #205fb0;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 34px;
  box-shadow: 1px 1px 10px #999;
  z-index: 100;
}

.table {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.table-responsive .table {
  overflow-x: auto;
}
.table-hover .table-row:hover {
  background-color: #dfdfdf;
}
.table-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  text-align: left;
  width: 100%;
}
.table-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  text-align: left;
  align-items: center;
}

.thead {
  font-weight: bold;
}
.butn {
  padding: 8px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 80px;
}

.butn-green {
  background-color: green;
  color: #fff;
}
.butn-red {
  background-color: red;
  color: #fff;
}
.ml-auto {
  margin-left: auto;
}
.text-right {
  text-align: right;
}

.justify-left {
  justify-content: left;
}
.justify-right {
  justify-content: right;
}

.pr-2 {
  padding-right: 8px;
}

#tidio-chat-iframe {
  bottom: 60px !important;
}


.goog-te-gadget-icon {
  display: none !important;
}

.table-body:nth-last-child() {
  margin-bottom: 100px;
}

.sg {
  font-family: Signika Negative, Arial, sans-serif !important;
}

.app-trading-day {
  font-size: 2.1rem
}

.app-trading-month {
  margin: 0;
  padding: 0;
  font-weight: 500;
}


.alert {
  border-radius: .125rem;
  /* border: 1px solid black; */
  padding: .5rem;
  color: black;
}

.alert-info {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert__primary {
  background-color: var(--alertPrimary);
}

.alert__secondary {
  background-color: var(--alertSecondary);
}

.alert__warning {
  background-color: var(--alertWarning);
}

.alert__error {
  background-color: var(--alertError);
}

.alert__success {
  background-color: var(--alertSuccess);
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

/*  */

.tabs {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  /* height: 48px; */
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  white-space: nowrap
}

.tabs.tabs-transparent {
  background-color: transparent
}

.tabs.tabs-transparent .tab a, .tabs.tabs-transparent .tab.disabled a, .tabs.tabs-transparent .tab.disabled a:hover {
  color: rgba(255, 255, 255, 0.7)
}

.tabs.tabs-transparent .tab a:hover, .tabs.tabs-transparent .tab a.active {
  color: #fff
}

.tabs.tabs-transparent .indicator {
  background-color: #fff
}

.tabs.tabs-fixed-width {
  display: flex
}

.tabs.tabs-fixed-width .tab {
  flex-grow: 1
}

.tabs .tab {
  display: inline-block;
  text-align: center;
  line-height: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
}

.tabs .tab a {
  color: rgba(238, 110, 115, 0.7);
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color .28s ease, background-color .28s ease
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
  background-color: rgba(246, 178, 181, 0.2);
  outline: none;
}

.tabs .tab a:hover, .tabs .tab a.active {
  background-color: transparent;
  color: #ee6e73;
  border-bottom: 2px solid black;
}

.tabs .tab.disabled a, .tabs .tab.disabled a:hover {
  color: rgba(238, 110, 115, 0.4);
  cursor: default
}

.tabs .indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #f6b2b5;
  will-change: left, right
}

.tab a.active {
  font-weight: bold;
  color: #6648fe;
}

.tab a {}

@media only screen and (max-width: 992px) {
  .tabs {
    display: flex
  }

  .tabs .tab {
    flex-grow: 1
  }

  .tabs .tab a {
    padding: 0 12px
  }
}


.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center
}

@media only screen and (max-width: 600px) {
  .hide-on-small-only, .hide-on-small-and-down {
    display: none !important
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important
  }
}

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important
  }
}

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important
  }
}

@media only screen and (min-width: 1201px) {
  .hide-on-extra-large-only {
    display: none !important
  }
}

@media only screen and (min-width: 1201px) {
  .show-on-extra-large {
    display: block !important
  }
}

@media only screen and (min-width: 993px) {
  .show-on-large {
    display: block !important
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important
  }
}

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important
  }
}

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important
  }
}

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important
  }
}

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center
  }
}

.col[class*="push-"], .col[class*="pull-"] {
  position: relative
}

.col.s1 {
  width: 8.33333%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s2 {
  width: 16.66667%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s4 {
  width: 33.33333%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s5 {
  width: 41.66667%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s7 {
  width: 58.33333%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s8 {
  width: 66.66667%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s10 {
  width: 83.33333%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s11 {
  width: 91.66667%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto
}

.col.offset-s1 {
  margin-left: 8.33333%
}

.col.pull-s1 {
  right: 8.33333%
}

.col.push-s1 {
  left: 8.33333%
}

.col.offset-s2 {
  margin-left: 16.66667%
}

.col.pull-s2 {
  right: 16.66667%
}

.col.push-s2 {
  left: 16.66667%
}

.col.offset-s3 {
  margin-left: 25%
}

.col.pull-s3 {
  right: 25%
}

.col.push-s3 {
  left: 25%
}

.col.offset-s4 {
  margin-left: 33.33333%
}

.col.pull-s4 {
  right: 33.33333%
}

.col.push-s4 {
  left: 33.33333%
}

.col.offset-s5 {
  margin-left: 41.66667%
}

.col.pull-s5 {
  right: 41.66667%
}

.col.push-s5 {
  left: 41.66667%
}

.col.offset-s6 {
  margin-left: 50%
}

.col.pull-s6 {
  right: 50%
}

.col.push-s6 {
  left: 50%
}

.col.offset-s7 {
  margin-left: 58.33333%
}

.col.pull-s7 {
  right: 58.33333%
}

.col.push-s7 {
  left: 58.33333%
}

.col.offset-s8 {
  margin-left: 66.66667%
}

.col.pull-s8 {
  right: 66.66667%
}

.col.push-s8 {
  left: 66.66667%
}

.col.offset-s9 {
  margin-left: 75%
}

.col.pull-s9 {
  right: 75%
}

.col.push-s9 {
  left: 75%
}

.col.offset-s10 {
  margin-left: 83.33333%
}

.col.pull-s10 {
  right: 83.33333%
}

.col.push-s10 {
  left: 83.33333%
}

.col.offset-s11 {
  margin-left: 91.66667%
}

.col.pull-s11 {
  right: 91.66667%
}

.col.push-s11 {
  left: 91.66667%
}

.col.offset-s12 {
  margin-left: 100%
}

.col.pull-s12 {
  right: 100%
}

.col.push-s12 {
  left: 100%
}

@media only screen and (min-width: 601px) {
  .col.m1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m2 {
    width: 16.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m4 {
    width: 33.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m5 {
    width: 41.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m7 {
    width: 58.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m8 {
    width: 66.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m10 {
    width: 83.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m11 {
    width: 91.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.offset-m1 {
    margin-left: 8.33333%
  }

  .col.pull-m1 {
    right: 8.33333%
  }

  .col.push-m1 {
    left: 8.33333%
  }

  .col.offset-m2 {
    margin-left: 16.66667%
  }

  .col.pull-m2 {
    right: 16.66667%
  }

  .col.push-m2 {
    left: 16.66667%
  }

  .col.offset-m3 {
    margin-left: 25%
  }

  .col.pull-m3 {
    right: 25%
  }

  .col.push-m3 {
    left: 25%
  }

  .col.offset-m4 {
    margin-left: 33.33333%
  }

  .col.pull-m4 {
    right: 33.33333%
  }

  .col.push-m4 {
    left: 33.33333%
  }

  .col.offset-m5 {
    margin-left: 41.66667%
  }

  .col.pull-m5 {
    right: 41.66667%
  }

  .col.push-m5 {
    left: 41.66667%
  }

  .col.offset-m6 {
    margin-left: 50%
  }

  .col.pull-m6 {
    right: 50%
  }

  .col.push-m6 {
    left: 50%
  }

  .col.offset-m7 {
    margin-left: 58.33333%
  }

  .col.pull-m7 {
    right: 58.33333%
  }

  .col.push-m7 {
    left: 58.33333%
  }

  .col.offset-m8 {
    margin-left: 66.66667%
  }

  .col.pull-m8 {
    right: 66.66667%
  }

  .col.push-m8 {
    left: 66.66667%
  }

  .col.offset-m9 {
    margin-left: 75%
  }

  .col.pull-m9 {
    right: 75%
  }

  .col.push-m9 {
    left: 75%
  }

  .col.offset-m10 {
    margin-left: 83.33333%
  }

  .col.pull-m10 {
    right: 83.33333%
  }

  .col.push-m10 {
    left: 83.33333%
  }

  .col.offset-m11 {
    margin-left: 91.66667%
  }

  .col.pull-m11 {
    right: 91.66667%
  }

  .col.push-m11 {
    left: 91.66667%
  }

  .col.offset-m12 {
    margin-left: 100%
  }

  .col.pull-m12 {
    right: 100%
  }

  .col.push-m12 {
    left: 100%
  }
}

@media only screen and (min-width: 993px) {
  .col.l1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l2 {
    width: 16.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l4 {
    width: 33.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l5 {
    width: 41.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l7 {
    width: 58.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l8 {
    width: 66.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l10 {
    width: 83.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l11 {
    width: 91.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.offset-l1 {
    margin-left: 8.33333%
  }

  .col.pull-l1 {
    right: 8.33333%
  }

  .col.push-l1 {
    left: 8.33333%
  }

  .col.offset-l2 {
    margin-left: 16.66667%
  }

  .col.pull-l2 {
    right: 16.66667%
  }

  .col.push-l2 {
    left: 16.66667%
  }

  .col.offset-l3 {
    margin-left: 25%
  }

  .col.pull-l3 {
    right: 25%
  }

  .col.push-l3 {
    left: 25%
  }

  .col.offset-l4 {
    margin-left: 33.33333%
  }

  .col.pull-l4 {
    right: 33.33333%
  }

  .col.push-l4 {
    left: 33.33333%
  }

  .col.offset-l5 {
    margin-left: 41.66667%
  }

  .col.pull-l5 {
    right: 41.66667%
  }

  .col.push-l5 {
    left: 41.66667%
  }

  .col.offset-l6 {
    margin-left: 50%
  }

  .col.pull-l6 {
    right: 50%
  }

  .col.push-l6 {
    left: 50%
  }

  .col.offset-l7 {
    margin-left: 58.33333%
  }

  .col.pull-l7 {
    right: 58.33333%
  }

  .col.push-l7 {
    left: 58.33333%
  }

  .col.offset-l8 {
    margin-left: 66.66667%
  }

  .col.pull-l8 {
    right: 66.66667%
  }

  .col.push-l8 {
    left: 66.66667%
  }

  .col.offset-l9 {
    margin-left: 75%
  }

  .col.pull-l9 {
    right: 75%
  }

  .col.push-l9 {
    left: 75%
  }

  .col.offset-l10 {
    margin-left: 83.33333%
  }

  .col.pull-l10 {
    right: 83.33333%
  }

  .col.push-l10 {
    left: 83.33333%
  }

  .col.offset-l11 {
    margin-left: 91.66667%
  }

  .col.pull-l11 {
    right: 91.66667%
  }

  .col.push-l11 {
    left: 91.66667%
  }

  .col.offset-l12 {
    margin-left: 100%
  }

  .col.pull-l12 {
    right: 100%
  }

  .col.push-l12 {
    left: 100%
  }
}

@media only screen and (min-width: 1201px) {
  .col.xl1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl2 {
    width: 16.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl4 {
    width: 33.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl5 {
    width: 41.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl7 {
    width: 58.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl8 {
    width: 66.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl10 {
    width: 83.33333%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl11 {
    width: 91.66667%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto
  }

  .col.offset-xl1 {
    margin-left: 8.33333%
  }

  .col.pull-xl1 {
    right: 8.33333%
  }

  .col.push-xl1 {
    left: 8.33333%
  }

  .col.offset-xl2 {
    margin-left: 16.66667%
  }

  .col.pull-xl2 {
    right: 16.66667%
  }

  .col.push-xl2 {
    left: 16.66667%
  }

  .col.offset-xl3 {
    margin-left: 25%
  }

  .col.pull-xl3 {
    right: 25%
  }

  .col.push-xl3 {
    left: 25%
  }

  .col.offset-xl4 {
    margin-left: 33.33333%
  }

  .col.pull-xl4 {
    right: 33.33333%
  }

  .col.push-xl4 {
    left: 33.33333%
  }

  .col.offset-xl5 {
    margin-left: 41.66667%
  }

  .col.pull-xl5 {
    right: 41.66667%
  }

  .col.push-xl5 {
    left: 41.66667%
  }

  .col.offset-xl6 {
    margin-left: 50%
  }

  .col.pull-xl6 {
    right: 50%
  }

  .col.push-xl6 {
    left: 50%
  }

  .col.offset-xl7 {
    margin-left: 58.33333%
  }

  .col.pull-xl7 {
    right: 58.33333%
  }

  .col.push-xl7 {
    left: 58.33333%
  }

  .col.offset-xl8 {
    margin-left: 66.66667%
  }

  .col.pull-xl8 {
    right: 66.66667%
  }

  .col.push-xl8 {
    left: 66.66667%
  }

  .col.offset-xl9 {
    margin-left: 75%
  }

  .col.pull-xl9 {
    right: 75%
  }

  .col.push-xl9 {
    left: 75%
  }

  .col.offset-xl10 {
    margin-left: 83.33333%
  }

  .col.pull-xl10 {
    right: 83.33333%
  }

  .col.push-xl10 {
    left: 83.33333%
  }

  .col.offset-xl11 {
    margin-left: 91.66667%
  }

  .col.pull-xl11 {
    right: 91.66667%
  }

  .col.push-xl11 {
    left: 91.66667%
  }

  .col.offset-xl12 {
    margin-left: 100%
  }

  .col.pull-xl12 {
    right: 100%
  }

  .col.push-xl12 {
    left: 100%
  }
}




.hero {
  text-align: center;
  padding: 50px 20px;
  height: 100%;
}

.hero h1 {
  margin: 0;
  font-size: 3em;
}

.hero p {
  font-size: 1.2em;
}

.hero button {
  background-color: #205fb0;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
}

.section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.footer {
  background-color: #2a2a2a;
  color: white;
  text-align: center;
  padding: 20px;
}

.trio-cards {
  background-color: #fff;
  border-radius: 12px;
  padding: 32px 20px;
  color: #07061f;
}

.benefit {
  margin: 10px 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 8px;
}

.div-block-7 {
  box-sizing: border-box;
  text-align: center;
  object-fit: fill;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.text-block-28 {
  color: #fff;
  background-color: #205fb0;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 36px;
  height: 100%;
  max-height: 36px;
  margin-bottom: 40px;
  font-weight: 700;
  display: flex;
}

.text-block-26 {
  color: #07061f;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  min-width: auto;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  display: flex;
}

.text-block-27 {
  color: #07061f;
  text-align: left;
  font-size: 14px;
  line-height: 130%;
}

@media screen and (max-width: 991px) {
  .heading-text-1 {
    font-size: 36px;
  }
}

.text-block-25 {
  color: var(--dub-brown);
  text-align: center;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  display: flex;
}

.button .section-button {
  margin-top: 40px;
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .button {
    text-align: center;
    font-size: 16px;
  }
}

.button {
  float: none;
  color: #0c0416;
  text-align: center;
  background-color: #fff;
  border-radius: 50px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 18px 40px;

  font-size: 18px;
  font-weight: 500;
  line-height: 115%;
  transition-property: background-color;
  display: inline-block;
  position: static;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.trio-cards-portfolios {
  background-color: #07061f;
  background-image: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(assets/663f7f3c8e43478ef7cc3571_􀞚.png);
  background-position: 0 0, 50% 0;
  background-size: auto, cover;
  border-radius: 12px;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 400px;
  height: 100%;
  min-height: 300px;
  max-height: 400px;
  padding: 32px 20px;
  display: flex;
  color: #fff;
}

.trio-cards-portfolios.jerome-powell {
  background-image: url("assets/663cdb8cab0fa07c145c39ef_20231007_WBP502.webp");
  background-position: 75%;
  background-size: cover;
}

.trio-cards-portfolios.nancy-pelosi {
  background-color: rgba(0, 0, 0, .4);
  background-image: linear-gradient(transparent, transparent), url("assets/663b7d6d2ffaf5ce22c5f343_time-100-Nancy-Pelosi.webp");
  background-size: auto, 300px;
}

.trio-cards-portfolios.bill-ackman {
  background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url("assets/gg.webp");
  background-position: 0 0, 50% 10%;
  background-size: auto, cover;
}

@media screen and (max-width: 479px) {
  .section-9 {
    margin-top: 12%;
  }
}

.section-9 {
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

.text-block-25 {
  color: var(--dub-brown);
  text-align: center;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  display: flex;
}

@media screen and (max-width: 479px) {
  .heading-text-1 {
    margin-bottom: 20px;
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .heading-text-1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 991px) {
  .heading-text-1 {
    font-size: 36px;
  }
}

.heading-text-1 {
  /* color: var(--dub-off-white); */
  text-align: center;
  margin-bottom: 20px;

  font-size: 40px;
  font-weight: 600;
  line-height: 120%;
}

@media screen and (max-width: 479px) {
  .cloneable-container-default {
    padding: 10px 16px 64px;
  }
}

@media screen and (max-width: 767px) {
  .cloneable-container-default {
    padding: 10px 24px 80px;
  }
}

@media screen and (max-width: 991px) {
  .cloneable-container-default {
    padding-top: 10px;
  }
}

.cloneable-container-default {
  border-radius: 20px;
  width: 100%;
  min-height: 624px;
  padding: 40px;
  transform: translate(0);
}

.accordion-item-wrapper---brix.preview-page {
  max-width: 1200px;
}

.accordion-item-wrapper---brix {
  max-width: 886px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 479px) {
  .accordion-item---brix.accordion-1---brix {
    background-color: #ebebeb;
    padding: 16px 20px 20px;
  }
}

@media screen and (max-width: 767px) {
  .accordion-item---brix.accordion-1---brix {
    margin-bottom: 16px;
    padding: 32px;
  }
}

@media screen and (max-width: 991px) {
  .accordion-item---brix.accordion-1---brix {
    margin-bottom: 20px;
  }
}

.accordion-item---brix.accordion-1---brix {
  border: 2px solid transparent;
  margin-bottom: 32px;
  padding: 20px 30px;
  transition: border-color .2s, box-shadow .2s;
  box-shadow: 0 5px 16px rgba(8, 15, 52, .06);
}

.accordion-item---brix {
  cursor: pointer;
  background-color: #fff;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  display: flex;
}

@media screen and (max-width: 479px) {
  .section-13 {
    align-self: center;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    overflow: visible;
  }
}

@media screen and (max-width: 767px) {
  .section-13 {
    margin-top: 70px;
  }
}

@media screen and (max-width: 991px) {
  .section-13, .section-14, .section-15 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.section-13 {
  z-index: 1;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
}

@media screen and (max-width: 479px) {
  .image-55 {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.image-54, .image-55 {
  display: none;
}


.feature {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 991px) {
  .div-block-16 {
    max-height: 64px;
  }
}

.div-block-16 {
  max-height: 72px;
  margin-bottom: 20px;
}

.image-61 {
  height: 100%;
}

@media screen and (max-width: 479px) {
  .grid-feature-2 {
    grid-template-columns: 1fr !important;
    margin-bottom: 0%;
  }
}

@media screen and (max-width: 767px) {
  .grid-feature-2 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-items: stretch;
    margin-bottom: 8%;
    display: grid;
  }
}

@media screen and (max-width: 991px) {
  .grid-feature-2 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.grid-feature-2 {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-width: 1080px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 767px) {
  .div-block-8.friends-layout {
    flex-flow: column;
    max-width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .div-block-8 {
    flex-flow: column;
    grid-auto-flow: row;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .div-block-8 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-flow: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 70%;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .div-block-8 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
}

.div-block-8 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.div-block-14 {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .text-block-36 {
    width: 94px;
    font-size: 12px;
  }
}

.text-block-36 {
  color: var(--dub-brown);
  background-color: #5b483e;
  border-radius: 20px;
  width: 110px;
  padding-top: 4px;
  padding-bottom: 4px;

  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

@media screen and (max-width: 767px) {
  .text-block-37 {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .text-block-37 {
    font-size: 14px;
  }
}

.text-block-37 {
  text-align: left;

  font-size: 16px;
  line-height: 140%;
}

@media screen and (max-width: 991px) {
  .column-6, .columns-3 {
    text-align: left;
  }
}

.column-6 {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .w-col-stack {
    width: 100%;
    left: auto;
    right: auto;
  }
}

.w-col-3 {
  width: 25%;
}

.w-col {
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .image-57 {
    width: 48px;
  }
}

.image-57 {
  border-radius: 32px;
  width: 64px;
  padding-top: 5px;
}

@media screen and (max-width: 479px) {
  .div-block-8.friends-layout {
    flex-flow: column;
    max-width: 100%;
  }

  .hero h1 {
    font-size: 2.3em;
  }
}

@media screen and (max-width: 767px) {
  .div-block-8.friends-layout {
    flex-flow: column;
    max-width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .div-block-8 {
    flex-flow: column;
    grid-auto-flow: row;
    display: flex !important;
  }
}

@media screen and (max-width: 767px) {
  .div-block-8 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-flow: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 70%;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .div-block-8 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
}

.div-block-8 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

@media screen and (max-width: 479px) {
  .paragraph-feature {
    max-width: 300px;
    font-size: 16px;
    line-height: 120%;
  }
}

@media screen and (max-width: 767px) {
  .paragraph-feature {
    max-width: 430px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.paragraph-feature {
  text-align: center;
  max-width: 330px;
  margin-bottom: 20px;

  font-size: 14px;
  /* font-weight: 300; */
  line-height: 130%;

}

.heading-feature {
  color: #edae85;
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
}

.bg-white-f {
  background-image: url("assets/pexels-sandra-filipe-64798-7087668.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.bg-geb {
  background-image: linear-gradient(rgba(0, 0, 0, .95), rgba(0, 0, 0, .95)), url("assets/bgg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  /* filter: blur(10px); */
}

.text-block-34 {
  text-align: left;
  font-size: 14px;
  line-height: 140%;
}



@media screen and (max-width: 767px) {
  .text-block-37 {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .text-block-37 {
    font-size: 14px;
  }
}

.text-block-37 {
  text-align: left;
  font-size: 16px;
  line-height: 140%;
}

@media screen and (max-width: 991px) {
  .text-block-33 {
    font-size: 16px;
  }
}

.text-block-33 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}

.glowing-wrapper.glowing-wrapper-active {
  margin-top: 20px;
  margin-bottom: 60px;
}

.glowing-wrapper {
  border-radius: 62.5rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.glowing-wrapper-active .glowing-wrapper-animations, .glowing-wrapper-active .glowing-wrapper-borders-masker {
  opacity: 1;
}

.glowing-wrapper-animations {
  pointer-events: none;
}

.glowing-wrapper-animations {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glowing-wrapper-active .glowing-wrapper-animations, .glowing-wrapper-active .glowing-wrapper-borders-masker {
  opacity: 1;
}

.glowing-wrapper-borders-masker {
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: xor;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.glowing-wrapper-borders-masker {
  opacity: 0;
  border-radius: 62.5rem;
  width: 100%;
  padding: .06rem;
  line-height: 100%;
  transition: opacity 1s;
  position: absolute;
  top: 0;
  left: 0;
}

.glowing-wrapper-button {
  z-index: 1;
  color: #fff;
  letter-spacing: -.01em;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 62.5rem;
  padding: 18px 40px;
  font-size: .88rem;
  line-height: 1.42;
  text-decoration: none;
  position: relative;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

a {
  /* color: var(--dub-brown); */
  transition: color .2s;
}

a {
  background-color: transparent;
}

.glowing-wrapper-borders, .glowing-wrapper-glow, .glowing-wrapper-mask {
  animation: borderTurn 2.5s infinite linear;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.glw-black {
  background-image: conic-gradient(from 0 at 50% 50%, rgba(15, 17, 145, 0.5) 0deg, rgba(7, 16, 139, 0) 60deg, rgba(255, 255, 255, 0) 310deg, rgba(18, 7, 143, 0.5) 360deg);
  animation: borderTurn 2.5s infinite linear;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.glw-light {
  background-image: conic-gradient(from 0 at 50% 50%, rgba(72, 70, 70, 0.5) 0deg, rgba(255, 255, 255, 0) 60deg, rgba(255, 255, 255, 0) 310deg, rgba(255, 255, 255, .5) 360deg);
  animation: borderTurn 2.5s infinite linear;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.glowing-wrapper-animations * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.glowing-wrapper-mask {
  opacity: 1;
}

.glowing-wrapper-glow {
  /* opacity: .12; */
  filter: blur(8px);
}

@keyframes borderTurn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.glowing-wrapper-borders {
  opacity: 1;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glowing-wrapper-borders {
  animation-name: borderTurnWithTranslate;
}

@keyframes borderTurnWithTranslate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.glowing-wrapper-animations:after, .glowing-wrapper-borders:after {
  clear: both;
  content: "";
  display: block;
}

.glowing-wrapper-animations:before, .glowing-wrapper-borders:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.glowing-wrapper-button:after {
  /* background: radial-gradient(85% 120% at 50% 120%, rgba(255, 255, 255, .24) 0%, rgba(255, 255, 255, 0) 100%); */
  border-radius: 999px;
  content: "";
  height: calc(100% + 4px);
  left: -2px;
  opacity: 0;
  position: absolute;
  top: -2px;
  transition: 1s all;
  width: calc(100% + 4px);
}

.glowing-wrapper-button {
  z-index: 1;
  color: #fff;
  letter-spacing: -.01em;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 62.5rem;
  padding: 18px 40px;
  font-size: .88rem;
  line-height: 1.42;
  text-decoration: none;
  position: relative;
}

@media screen and (max-width: 479px) {
  .image-45 {
    max-width: 90%;
  }

  .hero .card {
    margin: 30% auto !important;
  }
}

@media screen and (max-width: 767px) {
  .image-45 {
    max-width: 65%;
  }
}

@media screen and (max-width: 991px) {
  .image-45 {
    max-width: 50%;
  }
}

.image-45 {
  width: 500px;
  max-width: 100%;
}

.remove-tidio #tidio-chat {
  display: none;
}